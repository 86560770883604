/* eslint-disable no-underscore-dangle */
import React from 'react'

// Lazy loading
import loadable from '@loadable/component'

interface FlexContentProps {
  prefix?: string
  fields: any
  location?: any
}

interface FlexerProps {
  [key: string]: any
}

interface ComponentProps {
  fields: any
  location?: any
}

const FlexContentHandler: React.FC<FlexContentProps> = ({
  prefix = 'page_Flexcontent',
  fields,
  location = {},
}) => {
  if (!fields) {
    return null
  }

  const flexers: FlexerProps = {
    [`${prefix}_Flex_Banner`]: loadable(
      () => import('components/flex/Banner/BannerShell')
    ),
    [`${prefix}_Flex_Form`]: loadable(
      () => import('components/flex/Form/FormShell')
    ),
    [`${prefix}_Flex_Text`]: loadable(
      () => import('components/flex/Text/TextShell')
    ),
    [`${prefix}_Flex_PostTypes`]: loadable(
      () => import('components/flex/Posts/PostsShell')
    ),
    [`${prefix}_Flex_LatestPosts`]: loadable(
      () => import('components/flex/Posts/Latest/LatestShell')
    ),
    [`${prefix}_Flex_HighlightedPosts`]: loadable(
      () => import('components/flex/Posts/Highlighted/HighlightedShell')
    ),
    [`${prefix}_Flex_Usp`]: loadable(
      () => import('components/flex/Usp/UspShell')
    ),
    [`${prefix}_Flex_Cta`]: loadable(
      () => import('components/flex/Cta/CtaShell')
    ),
    [`${prefix}_Flex_Ctavoorwaarden`]: loadable(
      () => import('components/flex/Cta/CtaShell')
    ),
    [`${prefix}_Flex_Slider`]: loadable(
      () => import('components/flex/Slider/SliderShell')
    ),
    [`${prefix}_Flex_Hero`]: loadable(
      () => import('components/flex/Hero/HeroShell')
    ),
    [`${prefix}_Flex_Video`]: loadable(
      () => import('components/flex/Video/Video')
    ),
    [`${prefix}_Flex_Typeform`]: loadable(
      () => import('components/flex/TypeForm/TypeForm')
    ),
    [`${prefix}_Flex_Fragment`]: loadable(
      () => import('components/flex/Fragment')
    ),
  }

  if (!fields.flex) {
    return null
  }

  return (
    <>
      {fields.flex.map((section: any, index: number) => {
        if (!section.fieldGroupName || !flexers[section.fieldGroupName]) {
          return null
        }

        const Component: React.FC<ComponentProps> =
          flexers[section.fieldGroupName]

        return (
          <Component
            // eslint-disable-next-line react/no-array-index-key
            key={`${location ? location.pathname : 'flex'}-${index}`}
            fields={section}
            location={location}
          />
        )
      })}
    </>
  )
}

export default FlexContentHandler
