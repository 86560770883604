import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

import ButtonShell from 'components/elements/Buttons/ButtonShell'

export const ButtonSecondaryTransparentCSS = css`
  border: 2px solid ${({ theme }) => theme.color.primary};
  display: inline-block;
  text-align: center;
  min-width: 195px;

  & > a,
  & > button {
    font-weight: ${(props) => props.theme.font.weight.regular} !important;
    padding: 15px 30px;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none !important;
    color: ${({ theme }) => theme.color.primary} !important;
    &:hover {
      color: ${({ theme }) => theme.color.light} !important;
    }
  }
`

const StyledButton = styled(motion.span)`
  ${ButtonSecondaryTransparentCSS};
`

interface ButtonSecondaryTransparentProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
  target?: string
}

const ButtonSecondaryTransparent: React.FC<ButtonSecondaryTransparentProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
}) => {
  const [isHover, setIsHover] = useState(false)

  return (
    <StyledButton
      className={className}
      initial={{
        background: 'transparent',
      }}
      animate={
        isHover
          ? {
              backgroundColor: '#12155F',
              color: '#E9C080',
            }
          : {
              background: 'transparent',
              color: '#12155F',
            }
      }
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {isCustom ? (
        children
      ) : (
        <ButtonShell to={to} isAnchor={isAnchor} target={target}>
          {children}
        </ButtonShell>
      )}
    </StyledButton>
  )
}

export default ButtonSecondaryTransparent
